import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

export const getMarathonUserRigistered = createAsyncThunk(
  "personalCabinet/getMarathonUserRegistered",
  async (id) => {
    try {
      const { data } = await personalCabinetAPI.getMarathonUserRigistered(id);
      return data;
    } catch (error) { }
  }
);
