export const dividerStyles = {
  width: "75%",
  "@media screen and (max-width: 520px)": {
    width: "65%",
  },
  "@media screen and (max-width: 400px)": {
    width: "50%",
  },
  "@media screen and (max-width: 350px)": {
    width: "60%",
  },
};

export const activateOrDeactivateModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  padding: "2rem",
  border: "2px",
  borderRadius: "12px",
  background: "#fff",
  boxShadow: "0 4.8px 14.4px rgba(0,0,0,.18), 0 25.6px 57.6px rgba(0,0,0,.22)",
  outline: "none",
};

export const activateOrDeactivateBtn = {
  width: "150px",
  fontFamily: "Source Sans Pro",
  backgroundColor: "#205493",
  textTransform: "capitalize",
  padding: "0.43rem 1.3333rem",
  fontSize: "1rem",
  lineHeight: "1.333rem",
  borderRadius: "12px",
  marginRight: "16px",
  margin: "5px",
  "&:hover": {
    backgroundColor: "#007bff",
    borderRadius: "none",
  },
};

export const distanceDividerStyles = {
  width: "80%",
  "@media screen and (max-width: 550px)": {
    width: "75%",
  },
  "@media screen and (max-width: 450px)": {
    width: "70%",
  },
  "@media screen and (max-width: 370px)": {
    width: "60%",
  },
};

export const distanceForPWDDividerStyles = {
  width: "65%",
  "@media screen and (max-width: 600px)": {
    width: "60%",
  },
  "@media screen and (max-width: 500px)": {
    width: "55%",
  },
  "@media screen and (max-width: 450px)": {
    width: "50%",
  },
  "@media screen and (max-width: 400px)": {
    width: "45%",
  },
  "@media screen and (max-width: 385px)": {
    width: "40%",
  },
  "@media screen and (max-width: 350px)": {
    width: "30%",
  },
};

export const textFieldStyles = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px",
  },
  width: "49%",
};

export const periodSettingModalTitle = {
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  color: "#3E444A",
};

export const periodSettingModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "#fff",
  boxShadow:
    "0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22)",
  borderRadius: "7px",
  outline: "none",
  padding: "24px 30px 40px 24px",

  "@media screen and (max-width: 425px)": {
    width: "375px",
  },
  "@media screen and (max-width: 415px)": {
    width: "385px",
  },
  "@media screen and (max-width: 380px)": {
    width: "335px",
  },
  "@media screen and (max-width: 330px)": {
    width: "295px",
  },
};

export const periodSettingButtonStyles = {
  backgroundColor: "#F0F8FF",
  color: "#0090D1",
  textTransform: "none",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  padding: "8px 16px",
  borderRadius: "12px",
  border: "2px solid transparent",
  "&:hover": {
    borderColor: "#0090D1",
  },
};

export const periodSettingTextFieldStyles = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "12px",
  },
  width: "98%",
  marginTop: "5px",
};

export const saveModalButton = {
  width: "120px",
  backgroundColor: "#0090D1",
  borderRadius: "12px",
  padding: "8px 24px",
  textTransform: "none",
  color: "#fff",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 500,
  marginRight: "16px",
};

export const cancelModalButton = {
  width: "120px",
  backgroundColor: "#F0F8FF",
  borderRadius: "12px",
  padding: "8px 24px",
  textTransform: "none",
  color: "#0090D1",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 500,
  "&:hover": {
    background: "none",
  },
};

export const createCategoryBtnStyles = {
  color: "#0090D1",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "none",
};

export const deletePeriodCancelButton = {
  backgroundColor: "#FF6960",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: "#FF6960",
  },
};

export const marathonCreatedModalTitle = {
  fontWeight: "600",
  fontFamily: "Raleway",
  fontSize: "20px",
  lineHeight: "24px",
  color: "#3E444A",
  padding: "40px 0 20px 0",
};

export const marathonCreatedModalText = {
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#68717B",
  mb: "25px",
};

export const marathonCreatedModalButton = {
  fontFamily: "Raleway",
  background: "#0090D1",
  "&:hover": { background: "#0090D1" },
  marginTop: "15px",
  width: "100%",
  borderRadius: "12px",
  color: "#fff",
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "none",
  fontWeight: "500",
};

export const importExcelFileButton = {
  fontFamily: "Raleway",
  textTransform: "none",
  color: "#0090D1",
  fontSize: "14px",
  lineHeight: "24px",
  borderRadius: "12px",
  fontWeight: 500,
  "@media screen and (max-width: 550px)": {
    marginBottom: "10px",
  },
};

export const exportExcelFileButton = {
  fontFamily: "Raleway",
  backgroundColor: "#0090D1",
  borderRadius: "12px",
  textTransform: "none",
  fontWeight: 500,
  lineHeight: "24px",
  fontSize: "14px",
  marginLeft: "20px",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0090D1",
  },
  "@media screen and (max-width: 550px)": {
    marginLeft: "0",
  },
};

export const showFilterButton = {
  fontFamily: "Raleway",
  backgroundColor: "#F0F8FF",
  padding: "8px 16px",
  borderRadius: "12px",
  color: "#0090D1",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 500,
  textTransform: "none",
  flexShrink: 0,
  marginLeft: "10px",
  "&:hover": {
    backgroundColor: "#F0F8FF",
  },
  "@media screen and (max-width: 1300px)": {
    fontSize: "12px",
  },
};

export const inputLabel = {
  transform: "translate(10px, 10px)",
  fontWeight: 400,
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.25)",
  lineHeight: "24px",
};

export const createUserInputLabel = {
  transform: "translate(-110px, 33px)",
  fontSize: "14px",
  lineHeight: "24px",
  fontFamily: "Raleway, serif",
  fontWeight: "400",
  color: "rgba(0, 0, 0, 0.45)",
};

export const selectCategory = {
  borderRadius: "12px",
  overflowX: "auto",

  border: "1px solid #C0D4E3",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  ".MuiSelect-select": {
    padding: "10px 32px 10px 12px",
    color: "#3E444A",
    fontFamily: "Raleway",
    fontSize: "14px",
    lineHeight: "24px",
  },
};

export const createUserSelectCategory = {
  borderRadius: "12px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  ".MuiSelect-select": {
    padding: "8px 32px 10px 12px",
    color: "#3E444A",
    fontFamily: "Raleway",
    fontSize: "14px",
    lineHeight: "24px",
  },
};

export const editParticipantCountry = {
  border: "2px solid #C0D4E3",
  borderRadius: "12px",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  ".MuiSelect-select": {
    padding: "8px 8px 8px 12px",
    color: "#3E444A",
    fontFamily: "Raleway",
    fontSize: "14px",
    lineHeight: "24px",
  },
};

export const inputStyle = {
  "& fieldset": {
    borderRadius: "12px",
    border: "1px solid #C0D4E3",
  },

  "&::placeholder": {
    "@media screen and (max-width: 325px)": {
      fontSize: "0.8rem",
    },
  },
};

export const editParticipantCancelButton = {
  padding: "8px 32px",
  backgroundColor: "#F0F8FF",
  color: "#0090D1",
  borderRadius: "12px",
  marginRight: "25px",
  "&:hover": {
    backgroundColor: "#F0F8FF",
  },
};

export const editParticipantSaveButton = {
  padding: "8px 32px",
  backgroundColor: "#0090D1",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: "#0090D1",
  },
};

export const defaultCircularProgress = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
};

export const autoComplete = {
  width: 300,
  height: 200,
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "12px",
    border: "2px solid #C0D4E3",
    "&:hover": {
      borderColor: "red",
    },
  },

  "@media(max-width: 680px)": {
    width: 250,
  },
  "@media(max-width: 530px)": {
    width: 140,
  },

  ".MuiAutocomplete-root fieldset:hover": {
    borderColor: "red !important",
  },
  ".MuiAutocomplete-inputRoot": {
    padding: "2px 7px",
    fontWeight: "400",
    color: "#3E444A",
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Raleway",
    "&:hover": {
      borderColor: "red",
    },
  },
};

export const participantsListAutoComplete = {
  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "12px",
    border: "2px solid #C0D4E3",
    "&:hover": {
      borderColor: "red",
    },
  },
  ".MuiAutocomplete-root fieldset:hover": {
    borderColor: "red !important",
  },
  ".MuiAutocomplete-inputRoot": {
    padding: "6px 7px 6px 7px",
    fontWeight: "400",
    color: "#3E444A",
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Raleway",
    "&:hover": {
      borderColor: "red",
    },
  },
};

export const dateOfBirth = {
  ...periodSettingTextFieldStyles,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #C0D4E3",
    borderRadius: "12px",
  },
  width: "100%",
  ".MuiFormHelperText-root.Mui-error": {
    color: "#FF6960",
    marginLeft: "1px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "16px",
    fontFamily: "Raleway",
    "&:hover fieldset": {
      border: "2px solid #C0D4E3",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid rgba(0, 144, 209, 1)",
      boxShadow: "none",
    },
  },
};

export const toggleButtonStyles = {
  borderRadius: "0 12px 12px 0",
  padding: "8px 24px",
  border: "none",
  boxShadow: "none",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#68717B",
  textTransform: "capitalize",
  fontFamily: "Raleway",

  "@media (max-width: 420px)": {
    width: 80,
    fontSize: "12px",
  },
  // "@media (max-width: 700px)": {
  //   width: 80,
  //   fontSize: "12px",
  // }
};

export const toggleButtonStylesForSize = {
  borderRadius: "0 12px 12px 0",
  padding: "8px 24px",
  border: "none",
  boxShadow: "none",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#68717B",
  textTransform: "capitalize",
  fontFamily: "Raleway",
  "@media(max-width: 700px)": {
    borderRadius: "0",
  },
  // "@media (max-width: 420px)": {
  //   width: 80,
  //   fontSize: "12px",
  // },
  // "@media (max-width: 700px)": {
  //   width: 80,
  //   fontSize: "12px",
  // }
};

export const toggleButtonGroupStyles = {
  "@media(max-width: 700px)": {
    display: "flex",
    flexDirection: "column",
  },
};

export const participantListTableCell = {
  borderBottom: "none",
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  paddingLeft: "8px",
};

export const starterKitModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "549px",
  backgroundColor: "#fff",
  boxShadow:
    "0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22)",
  borderRadius: "7px",
  outline: "none",
  padding: "24px 30px 40px 24px",

  "@media screen and (max-width: 580px)": {
    width: "445px",
  },
  "@media screen and (max-width: 430px)": {
    width: "400px",
  },
  "@media screen and (max-width: 380px)": {
    width: "325px",
  },
  "@media screen and (max-width: 325px)": {
    width: "280px",
  },
};

export const starterKitModalTitle = {
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  color: "#3E444A",

  textWrap: "wrap",
  "@media screen and (max-width: 480px)": {
    fontSize: "15px",
  },
};

export const starterKitTypography = {
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#3E444A",

  "@media screen and (max-width: 480px)": {
    fontSize: "13px",
  },
};

export const starterKitButtons = {
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  borderRadius: "12px",
  textTransform: "none",
  height: "40px",
  marginLeft: "8px",
};

export const createVoucherButton = {
  padding: "8px 16px",
  borderRadius: "12px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#0090D1",
  },
};

export const createVoucherModal = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "540px",
  maxHeight: "80vh",
  // overflow: "auto",
  backgroundColor: "#fff",
  outline: "none",
  borderRadius: "8px",
  p: 3,
  overflow: "hidden",

  "& .MuiFormGroup-root": {
    webkitFlexDirection: "row",
    flexDirection: "row",
    gap: "95px",
  },

  "@media screen and (max-width: 470px)": {
    width: 350,
  },
  "@media screen and (max-width: 380px)": {
    width: 280,
  },
};

export const ModalTitle = {
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
  color: "#3E444A",
  paddingInline: 0,
};

export const createVoucherAutoComplete = {
  "& fieldset": {
    borderRadius: "12px",
    border: "2px solid #C0D4E3",
  },

  "& .MuiOutlinedInput-input": {
    height: "40px",
    padding: "8px 12px",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px",
    },
  },
};

export const createVoucherDistanceName = {
  "& .MuiOutlinedInput-input": {
    padding: "8px 12px",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px",
    },
  },
  "& fieldset": {
    border: "2px solid #C0D4E3",
    borderRadius: "12px",
  },
};

export const createVoucherDistance = {
  // transform: "translate(10px, 40px)",
  fontWeight: 400,
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.25)",
  lineHeight: "24px",
};

export const Input = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "2px solid #C0D4E3",
    borderRadius: "12px",
  },
  ".Mui-error": {
    borderColor: "#d32f2f",
  },
  input: {
    fontFamily: "Raleway",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3E444A",
    "&::placeholder": {
      fontFamily: "Raleway",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
};

export const Label = {
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#68717B",
  marginBottom: "4px",
};

export const AutoCompleteInput = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "2px solid #C0D4E3",
    borderRadius: "12px",
    // maxHeight: "200px", // Ограничиваем высоту выпадающего списка
    zIndex: 10,
    overflow: "visible",
  },
  input: {
    fontFamily: "Raleway",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3E444A",
    "&::placeholder": {
      fontFamily: "Raleway",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
};

export const voucherListAddButton = {
  backgroundColor: "#0090D1",
  padding: "8px 16px",
  borderRadius: "12px",
  color: "#fff",
  fontWeight: 500,
  lineHeight: "24px",
  fontSize: "14px",
  textTransform: "capitalize",
  marginLeft: "10px",
  marginRight: "10px",
  "&:hover": {
    backgroundColor: "#0090D1",
  },
};

export const voucherListDownloadExcelButton = {
  color: "#0090D1",
  fontWeight: 500,
  padding: "8px 16px",
  borderRadius: "12px",
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "capitalize",
};

export const deleteVoucherPromocodesByIdButton = {
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#FF6960",
  textTransform: "none",
  borderRadius: "12px",
};

export const deleteVoucherPromocodesListCell = {
  border: "none",
  color: "#3E444A",
  fontFamily: "Raleway",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  paddingLeft: "6px",
};

export const datePickerVoucher = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "12px",
    border: "2px solid #C0D4E3",
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
  },
};

export const createUserButton = {
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "24px",
  color: "#fff",
  background: "#0090D1",
  padding: "8px 16px",
  borderRadius: "16px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#0090D1",
  },
};

export const marathonCardMenuItem = {
  display: "flex",
  borderRadius: "8px",
  padding: "10px",
  fontFamily: "Raleway",
  fontWeight: "500",
  fontSize: "14px",
  color: "#3E444A",
  lineHeight: "20px",
  "&:hover": {
    background: "#F0F8FF",
    color: "#0090D1",
  },
};

export const circularProgressWrapperStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "30px 0 10px 0",
};
