import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { personalCabinetAPI } from "../api/index";

interface postApplicationMoneyCreateThunk {
  distanceId: number | undefined;
}

export const postApplicationMoneyCreateThunk = createAsyncThunk(
  "personalCabinet/postApplicationMoney",
  async (data: postApplicationMoneyCreateThunk, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.postApplicationMoney(data)
      if (res.data) {
        window.open(res.data, "_blank");
      } else {
        window.location.reload();
      }
      return res.data;
    } catch (error: any) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title)
    }
  }
);
