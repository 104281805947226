import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import clsx from "clsx";
import styles from "./ui-text-field.module.scss";

export type UiTextFieldProps = {
  className?: string;
  label?: string;
  error?: any;
  name?: string;
  inputProps?: TextFieldProps;
  placeholder?: string;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  isMessageUnBlock?: boolean;
  type?: string;
};

export function UiTextField({
  className,
  error,
  label,
  inputProps,
  name,
  placeholder,
  InputProps,
  isMessageUnBlock,
  type = "text",
}: UiTextFieldProps) {
  const id = label! + Math.random();
  return (
    <div className={clsx(className, styles.inputWrapper)}>
      <label htmlFor={String(id)} style={{ height: "17px" }} className="block">
        {label}
      </label>
      <TextField
        className={clsx(inputProps?.className, styles.textField)}
        type={type}
        id={String(id)}
        error={!!error}
        focused={!!error}
        placeholder={placeholder}
        helperText={error}
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "13px",
            border: "1px solid #C0D4E3",
          },
          "& .MuiFormHelperText-root": {
            position: isMessageUnBlock ? "absolute" : "unset",
            bottom: isMessageUnBlock ? "-20px" : "",
          },
        }}
        inputProps={{ min: 0 }}
        {...inputProps}
        InputProps={InputProps}
      />
      {/* {error && <ErrorMessage errors={error} name={name} />} */}
    </div>
  );
}
