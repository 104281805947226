import { AutocompleteRenderInputParams, TextField } from "@mui/material";
import styles from "./ui-text-field.module.scss";
import { FieldError } from "react-hook-form";

export type UiTextFieldProps = {
  params: AutocompleteRenderInputParams;
  label: string;
  error: FieldError | undefined;
  placeholder: string;
};

export function UiTextAutoComplete({
  params,
  label,
  error,
  placeholder,
}: UiTextFieldProps) {
  const id = label! + Math.random();
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={id} style={{ height: "17px" }} className="block">
        {label}
      </label>
      <TextField
        {...params}
        error={!!error?.type}
        helperText={error?.message}
        className={styles.textField}
        placeholder={placeholder}
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "13px",
            border: "1px solid #C0D4E3",
          },
          "& .MuiOutlinedInput-root": {
            height: "40px",
          },
          "& .MuiFormHelperText-root": {
            position: "absolute",
            bottom: -2,
            height: "0",
            marginTop: "0",
          },
        }}
      />
    </div>
  );
}
