import { Box, Tabs } from "@mui/material";

import styles from "./marathon.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { ICreateMarathon } from "features/createMarathon/types";

export const MarathonNav = () => {
  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();

  const hasStep1Errors =
    errors.dateEvent || errors.documents || errors.translations;

  const hasStep2Errors =
    errors.startDateAcceptingApplications ||
    errors.endDateAcceptingApplications ||
    errors.participantNumbersPrintDate ||
    errors.insurancePrice;

  return (
    <Box className={styles.nav}>
      <div className={hasStep1Errors ? styles.nav_error : undefined}>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.nav_activeLink : styles.nav_link
          }
          to={"/new/step-1"}
        >
          <div className={styles.nav_line}></div>
          <h5 className={styles.nav_title}>Шаг 1. Контент</h5>
          <p className={styles.nav_text}>
            Заполните основную информацию на трех языках и загрузите документы
          </p>
        </NavLink>
      </div>
      <div className={hasStep2Errors ? styles.nav_error : undefined}>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.nav_activeLink : undefined
          }
          to={"/new/step-2"}
        >
          <div className={styles.nav_line}></div>
          <h5 className={styles.nav_title}>Шаг 2. Настройки</h5>
          <p className={styles.nav_text}>
            Установите даты приема заявок, создайте <br /> и настройте дистанции
          </p>
        </NavLink>
      </div>
    </Box>
  );
};
