import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";




export const unAsignPromocode = createAsyncThunk(
  "user/unasignPromocode",
  async (query: any, { rejectWithValue, signal }) => {
    try {
      const { data } = await adminAPI.unPostAsignPromocode(query, signal);
      return data;
    } catch (error: any) {
      console.log(error);

      return rejectWithValue("Error");
    }
  }
);