import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import { SuccessIcon } from "assets/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./promoStyle.module.scss";

import { unAsignPromocode } from "entities/admin/model/thunk/unasignPromocodeById";
import { useTranslation } from "react-i18next";

interface PromocodeModal {
  open: boolean;
  onClose: () => void;
  promocode: any;
  promocodeEmail: string;
  handleUpdateState: any;
}

export const SuccessAsignPromode = ({
  onClose,
  open,
  promocode,
  promocodeEmail,
  handleUpdateState,
}: PromocodeModal) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  // const debouncedValue = useDebounce<string>(data.email, 800);
  // useEffect(() => {
  //   // @ts-ignore
  //   const promise = dispatch(getVerifiedUsers(debouncedValue));
  //   return () => {
  //     promise.abort();
  //   };
  // }, [dispatch, debouncedValue]);

  const handleClose = async () => {
    onClose();
  };

  const unSignPromodeHandle = async () => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      unAsignPromocode({ promocodeId: promocode.id })
    );
    await handleUpdateState();
    setLoading(false);
    handleClose();
  };
  const { t, i18n } = useTranslation();
  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box className={styles.modal}>
        <Box className={styles.modal_content}>
          <SuccessIcon className={styles.icon} />
          <Typography className={styles.title} variant="h3" my="16px">
            {t("success_message_promocode")}
          </Typography>
          <Typography className={styles.body} variant="body1">
            {i18n.language === "kg" ? (
              <>
                {t("message_finish_success_promocode")} {promocodeEmail}
                {t("message_finish_success_promocode2")} {promocode?.code}
              </>
            ) : (
              <>
                {t("message_finish_success_promocode")} {promocodeEmail}
                {t("message_finish_success_promocode2")} {promocode?.code}
              </>
            )}
          </Typography>
          <Divider sx={{ width: "100%", mb: "24px" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "8px",
            }}
          >
            <Button
              sx={{
                fontFamily: "Raleway",
                textTransform: "none",
                fontWeight: "500",
                position: "relative",
                borderRadius: "12px",
                color: "#0090D1",
                minWidth: "170px",
              }}
              onClick={unSignPromodeHandle}
            >
              {loading ? (
                <CircularProgress size={20} className={styles.buttonProgress} />
              ) : (
                "Отвязать промокод"
              )}
            </Button>
            <Button
              sx={{
                flex: 1,
                fontFamily: "Raleway",
                textTransform: "none",
                fontWeight: "500",
                position: "relative",
                background: "#0090D1",
                borderRadius: "12px",
                height: "40px",
                color: "white",
                "&:hover": { backgroundColor: "#0090D1" },
              }}
              onClick={handleClose}
            >
              Перейти к списку промокодов
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
