import axios from "axios";
import axiosApi from "./../../../../shared/api/axiosApi";
import { checkCurrentLanguage } from "../../../../shared/lib/checkCurrentLanguage";

export const personalCabinetAPI = {
  getPublicOffer: async (id) => {
    return await axiosApi.get(`/agreements/marathon/${id}`, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  isCorporateClient: async (email) => {
    return await axiosApi.get(
      `corporateclients/${email}/isusercorporateclient`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getVouchers: async (payload) => {
    return axiosApi.get(
      `vouchers/voucherbycorporateclient?corporateClientId=${payload.corporateClientId
      }${payload.pagination &&
      `&pageSize=${payload.pagination.pageSize}&page=${payload.pagination.page}`
      }${payload.sorting[0]
        ? payload.sorting[0].desc
          ? `&orderBy=${payload.sorting[0].id}%20desc`
          : `&orderBy=${payload.sorting[0].id}%20asc`
        : ""
      }`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getVoucherDistances: async ({ id, corporateClientId }) => {
    return axiosApi(
      `vouchers/${id}/distancesbyvoucher?corporateClientId=${corporateClientId}`
    );
  },
  getSubVouchers: async ({ voucherId, corporateClientId, pagination }) => {
    let corporateClientIdSection = corporateClientId
      ? `?corporateClientId=${corporateClientId}`
      : "";
    return axiosApi.get(
      `vouchers/${voucherId}/subvouchers` +
      corporateClientIdSection +
      `${pagination &&
      `${corporateClientIdSection ? "&pageSize" : "?pageSize"}=${pagination.pageSize
      }&page=${pagination.page}`
      }`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getProfile: async () => {
    return await axiosApi.get("/users/me", {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  getFiles: async () => {
    return axiosApi.get("/documents", {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  getDowloadFile: async (url) => {
    return axios.get(url, {
      responseType: "blob",
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postChangePassword: async (userPasswords) => {
    return await axiosApi.post("/auth/changepassword", userPasswords, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  putProfileEdit: async (userData) => {
    return await axiosApi.put("/users/me", userData, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  // putFiles: async (fileData) => {
  //   const params = new URLSearchParams();
  //   if (fileData.userId) {
  //     params.append("userId", fileData.userId);
  //   }
  //   return await axiosApi.put(
  //     `/documents?${params.toString()}`,
  //     { document: fileData.document, documentType: fileData.documentType },
  //     {
  //       headers: { "content-type": "multipart/form-data" },
  //     }
  //   );
  // },
  putFiles: async (filesArray) => {
    const formData = new FormData();

    filesArray.forEach((fileObj, index) => {
      formData.append(`Documents[${index}].Document`, fileObj.document);
      formData.append(`Documents[${index}].DocumentType`, fileObj.documentType);
    });

    return await axiosApi.put(
      `/documents`,
      formData,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
  },
  deleteFile: async (data) => {
    const params = new URLSearchParams();
    if (data.userId) {
      params.append("userId", data.userId);
    }
    if (data.documentType) {
      params.append("documentType", data.documentType);
    }
    return await axiosApi.delete(`/documents?${params.toString()}`);
  },
  getMarathons: ({ pagination, debouncedValue } = {}) => {
    return axiosApi.get(
      "/marathons?orderBy=Id%20desc" +
      `${pagination
        ? `&pageSize=${pagination.pageSize}&page=${pagination.page} ${debouncedValue ? `&filter=name%20%3D%2A${debouncedValue}/i` : ""
        }`
        : ""
      }`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getMarathon: async (id) => {
    return await axiosApi.get(`/marathons/${id}`, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  getMarathonUserRigistered: async (id) => {
    return await axiosApi.get(`/marathons/${id}/userRegistered`, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postApplication: async (data) => {
    return await axiosApi.post("/applications",
      { distanceId: data.distanceId, promocode: data.promocode }, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  changeDistance: async (data) => {
    return await axiosApi.post("/applications/change-distance", data, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postApplicationWithPromocode: async (data) => {
    return await axiosApi.post("/applications/insurance-promocode", data, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postApplicationPwd: async (data) => {
    return await axiosApi.post("/applications/pwd", { distanceId: data.distanceId }, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postApplicationPromoInsurance: async (data) => {
    return await axiosApi.post("/applications/insurance-promocode",
      { distanceId: data.distanceId, promocode: data.promocode }, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postApplicationPwdWithInsurance: async (data) => {
    return await axiosApi.post("/applications/insurance-pwd", data, {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  getMarathonDistancePrice: async (data) => {
    const { marathonId, distanceId, promocodeId, includeInsurance } = data;
    const params = {};
    if (promocodeId) params.promocodeId = promocodeId;
    if (includeInsurance !== undefined) params.includeInsurance = includeInsurance;
    return await axiosApi.get(`/marathons/${marathonId}/distances/${distanceId}/price`, {
      params,
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  postSubVoucher: async ({ subVoucherData, corporateClientId }) => {
    return axiosApi.post(
      `vouchers/subvoucher?corporateClientId=${corporateClientId}`,
      subVoucherData,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  postApplicationMoney: async (data) => {
    return await axiosApi.post(
      "/applications/money",
      {
        distanceId: data.distanceId
      },
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  postApplicationInsurance: async (data) => {
    return await axiosApi.post(
      "/applications/insurance",
      {
        distanceId: data.distanceId,
      },
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getStatuses: async () => {
    return axiosApi.get("statuses/me");
  },
  getMyApplications: async () => {
    return await axiosApi.get("/applications/myApplications", {
      headers: {
        "Accept-Language": checkCurrentLanguage(),
      },
    });
  },
  getMyResults: async () => {
    return await axiosApi.get("/results/my");
  },
  getMyResultAsPDF: async (resultId) => {
    return await axiosApi.get(`/results/${resultId}/print`);
  },
  postVouchers: async (data) => {
    return await axiosApi.post(`/vouchers`, data);
  },
  // getCorporateClients: async () => {
  //   return await axiosApi.get("corporateclients");
  // },
  getCorporateClients: async (voucherName) => {
    return await axiosApi.get(
      `/corporateclients?filter=${voucherName}page=1&pageSize=500&orderBy=Id%20desc`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getRegistrationUnfinishedMarathons: async () => {
    return await axiosApi.get(
      `/marathons/GetMyMarathonsWithUnfinishedRegistaration`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
};
