import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { personalCabinetAPI } from "../api/index";


interface postApplicationPromoInsurance {
  distanceId: number | null | undefined;
  promocode: string | undefined;
}

export const postApplicationPromoInsurance = createAsyncThunk(
  "personalCabinet/postApplicationPromoInsurance",
  async (data: postApplicationPromoInsurance, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.postApplicationPromoInsurance(data);
      if (res.data) {
        window.open(res.data, "_blank");
      } else {
        window.location.reload();
      }
      return res.data;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response ? error.response.data.title : "Unknown error");
    }
  }
);