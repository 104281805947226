import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from "../api/typedApi";
import { toast } from "react-toastify";

interface ISubVoucherData {
  subVoucherId: number;
  name: string;
  isActive: boolean;
  startKitIssueType: number;
}

export const updateSubVoucher = createAsyncThunk(
  'user/updateSubvoucher',
  async (subVoucherData: ISubVoucherData, { rejectWithValue }) => {
    try {
      const response = await typedAdminAPI.deactivateSubVoucher(subVoucherData);

      toast.success(`Субваучер успешно Изменён`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return response.data;
    } catch (error: any) {
      if (error.response?.data?.status === 404) {
        toast.error('Субваучер не найден', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return rejectWithValue('Субваучер не найден');
      }

      toast.error('Ой, что-то пошло не так, попробуйте снова', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return rejectWithValue('Ой, что-то пошло не так, попробуйте снова');
    }
  }
);