import { FC } from "react";
import { useTranslation } from "react-i18next";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import ButtonWithProgress from "shared/ui/ButtonWIthProgress/ButtonWithProgress";
import {
  cancelModalButton,
  activateOrDeactivateModal,
  activateOrDeactivateBtn,
  saveModalButton,
} from "../../../../entities/admin/lib/MUIStyles/MUIStyles";
import styles from "./leaveConfirmationModal.module.scss";

import { Typography } from "@mui/material";
import closeIcon from "../../../../assets/images/modal/x.svg";

const LeaveConfirmationModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: Function;
  actionName: string;
  instanceName: string;
  message?: string;
  loading?: boolean;
  handleAction: Function;
}> = ({
  isModalOpen,
  setIsModalOpen,
  actionName,
  instanceName,
  message,
  handleAction,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isModalOpen || false}
        onClose={() => setIsModalOpen({ isOpen: false })}
      >
        <Box sx={activateOrDeactivateModal}>
          {actionName && instanceName && (
            <div className={styles.activateModalTopWrapper}>
              <h3 className={styles.activateModalTitle}>
                {t(`buttons.${actionName}`)}{" "}
                {t(`catalogActions.${instanceName}`) || " "}
              </h3>
              <img
                src={closeIcon}
                alt=""
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          )}
          <p className={styles.activateModalText}>
            {t("notificationsPage.areYouSure")}{" "}
            {actionName && t(`buttons.${actionName}`)}{" "}
            {instanceName && t(`catalogActions.${instanceName}`) + "?"}
            {message && t(`notificationsPage.${message}`)}
          </p>
          <div className={styles.activateModalButtonWrapper}>
            <Button
              onClick={() => setIsModalOpen(false)}
              variant="text"
              sx={{ ...cancelModalButton, margin: "5px", width: "150px" }}
            >
              {t("buttons.cancel")}
            </Button>
            <ButtonWithProgress
              loading={Boolean(loading)}
              disabled={Boolean(loading)}
              variant="contained"
              onClick={() => handleAction()}
              sx={activateOrDeactivateBtn}
            >
              {t("buttons.Close")}
            </ButtonWithProgress>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LeaveConfirmationModal;
