import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { CloseIcon } from "assets/icons";
import { asignPromocode } from "entities/admin/model/thunk/asignPromocodeByEmail";
import { getVerifiedUsers } from "entities/admin/model/thunk/getVerifiedUsersForVoucher";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAppSelector, useDebounce } from "shared/lib/hooks/hooks";
import ButtonWithProgress from "shared/ui/ButtonWIthProgress/ButtonWithProgress";
import { UiTextAutoComplete } from "shared/ui/UiInputs/UITextAutoComplete";

import * as yup from "yup";

interface PromocodeModalProps {
  open: boolean;
  onClose: () => void;
  promocode: any;
  setSuccessPromode: any;
  setPromocodeEmail: any;
  handleUpdateState: any;
}
interface Status {
  id: number;
  currentStatus: string;
}
interface UserRole {
  id: number;
  role: string;
}
interface User {
  id: string;
  email: string;
  name: string;
  surname: string;
  nativeName: string;
  nativeSurname: string;
  fullName: string;
  fullNameR: string;
  dateOfBirth: string;
  gender: boolean;
  country: number;
  phoneNumber: string;
  isDeleted: boolean;
  emailConfirmed: boolean;
  document: Document;
  status: Status;
  userRoles: UserRole[];
  marathon: null | any;
}

export const PromoModal: FC<PromocodeModalProps> = ({
  onClose,
  open,
  promocode,
  setSuccessPromode,
  setPromocodeEmail,
  handleUpdateState,
}) => {
  const dispatch = useDispatch();
  const users = useAppSelector((state: any) => state.marathon.users);
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const { control, handleSubmit, reset, clearErrors, watch, setError } =
    useForm({
      defaultValues: {
        email: "",
      },
      resolver: yupResolver(
        yup.object().shape({
          email: yup
            .string()
            .email("Выберите Email")
            .required("Выберите Email"),
        })
      ),
    });

  const data = watch();
  const { t } = useTranslation();
  const debouncedValue = useDebounce<string>(data.email, 800);

  useEffect(() => {
    // @ts-ignore
    const promise = dispatch(getVerifiedUsers(debouncedValue));
    return () => {
      promise.abort();
    };
  }, [dispatch, debouncedValue]);

  const handleClose = () => {
    reset();
    clearErrors();
    onClose();
  };

  const onSubmit = async (value: { email: string }) => {
    setLoading(true);
    const res = await dispatch(
      // @ts-ignore
      asignPromocode({ promocodeId: promocode.id, email: value.email })
    );
    if (res?.meta?.requestStatus === "fulfilled") {
      handleClose();
      setSuccessPromode(true);
      setPromocodeEmail(data.email);
      setLoading(false);
    }

    if (res?.payload?.response?.data?.title) {
      setError("email", {
        message: res?.payload?.response?.data?.detail,
        type: "onChange",
      });
      setLoading(false);
      return;
    }
    handleUpdateState();
    setLoading(false);
  };

  const handleInputChange = (newInputValue: string) => {
    if (!newInputValue) {
      setFilteredUsers([]);
      return;
    }

    const lowerCasedInput = newInputValue.toLowerCase();
    const filteredOptions = users?.query
      ? users.query.filter((user: User) => {
          const fullName =
            `${user.email} ${user.name} ${user.surname}`.toLowerCase();
          return (
            user.email.toLowerCase().includes(lowerCasedInput) ||
            user.name.toLowerCase().includes(lowerCasedInput) ||
            user.surname.toLowerCase().includes(lowerCasedInput) ||
            fullName.includes(lowerCasedInput)
          );
        })
      : [];

    setFilteredUsers(filteredOptions);
  };

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            background: "white",
            padding: "24px",
            width: "511px",
            height: "229px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "top",
              mb: "8px",
            }}
          >
            <Typography
              sx={{
                color: "#3E444A",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {t("booking_promocode")}
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Controller
            name="email"
            control={control}
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={filteredUsers}
                  getOptionLabel={(filteredUsers: User) =>
                    `${filteredUsers.email} ${filteredUsers.name} ${filteredUsers.surname}`
                  }
                  onChange={(_, newValue) => {
                    onChange(newValue?.email || "");
                  }}
                  onInputChange={(_, newInputValue) => {
                    handleInputChange(newInputValue);
                  }}
                  onBlur={onBlur}
                  renderInput={(params) => (
                    <UiTextAutoComplete
                      error={error}
                      params={params}
                      label={t("vauchersPage.enterEmailOrName")}
                      placeholder={t("enterEmail")}
                    />
                  )}
                  renderOption={(props, filteredUsers: any) => {
                    return (
                      <li
                        {...props}
                        key={filteredUsers.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {filteredUsers.email} {filteredUsers.name}{" "}
                          {filteredUsers.surname}
                        </span>
                        <span
                          style={{
                            fontSize: 12,
                            color: "#68717B",
                          }}
                        ></span>
                      </li>
                    );
                  }}
                />
              );
            }}
          />
          <Divider sx={{ mt: "42px" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: "8px",
              gap: "16px",
            }}
          >
            <Button
              sx={{
                fontFamily: "Raleway",
                textTransform: "capitalize",
                fontWeight: "500",
                position: "relative",
                borderRadius: "12px",
                color: "#0090D1",
              }}
              variant="text"
              onClick={handleClose}
            >
              {t("corporateClient.cancel")}
            </Button>
            <ButtonWithProgress
              loading={loading}
              sx={{
                fontFamily: "Raleway",
                textTransform: "capitalize",
                fontWeight: "500",
                position: "relative",
                background: "#0090D1",
                borderRadius: "12px",
                width: "153px",
                height: "40px",
                "&:hover": { backgroundColor: "#0090D1" },
              }}
              variant="contained"
              type="submit"
            >
              {t("asign")}
            </ButtonWithProgress>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
