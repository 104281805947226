import { createAsyncThunk } from "@reduxjs/toolkit";
import { typedAdminAPI } from '../api/typedApi';

export interface IGetUserPromocodesBody {
  marathonId: string;
  userId: any;
}

export const getUserPromocodes = createAsyncThunk(
  'user/getUserPromocodes', async (
    data: IGetUserPromocodesBody, { rejectWithValue }
  ) => {
  try {
    const response = await typedAdminAPI.getUserPromocodes(data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue('Ой, что-то пошло не так, попробуйте обновить страницу');
  }
}
)
