import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "shared/lib/hooks/hooks";
import CustomNavigate from "shared/lib/CustomNavigate/CustomNavigate";
import Header from "widget/Header/Header";
import AdminHeader from "widget/AdminHeader/AdminHeader";
import { USER_LOGIN_PATH } from "pages/paths";
import { HubConnection } from "@microsoft/signalr";
import { createSignalRConnection } from "shared/lib/signalr";
import {
  setAllUsersNotificationsCount,
  setDisabledUsersNotificationsCount,
  setUnreadNotificationsCount,
} from "entities/admin/model/slice";

const Layout: React.FC = (): JSX.Element => {
  const userRole = useAppSelector((state) => state.users.userRole);
  const isAuth = useAppSelector((state) => state.users.isAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    const client = createSignalRConnection();
    setConnection(client);

    return () => {
      if (client) {
        client.stop();
      }
    };
  }, [isAuth]);

  useEffect(() => {
    if (connection) {
      connection.on("ReceiveNotification", (message) => {
        // console.log("Message ReceiveNotification:", message);
      });
      connection.on("UpdateUnreadCount", (message) => {
        dispatch(setUnreadNotificationsCount(parseInt(message, 10)));
        // console.log("Message UpdateUnreadCount:", message);
      });
      connection.on("UpdateAllCount", (message) => {
        dispatch(setAllUsersNotificationsCount(parseInt(message, 10)));
        // console.log("Message UpdateAllCount:", message);
      });
      connection.on("UpdateDisableCount", (message) => {
        dispatch(setDisabledUsersNotificationsCount(parseInt(message, 10)));
        // console.log("Message UpdateDisableCount:", message);
      });
    }
  }, [connection, dispatch]);

  useEffect(() => {
    if (
      !localStorage.getItem("AccessToken") &&
      location.pathname.includes("/personal_cabinet")
    ) {
      navigate(USER_LOGIN_PATH, {
        state: { from: location.pathname },
      });
    }
  }, []);

  return (
    <>
      <CustomNavigate />
      {userRole === "Admin" ||
      userRole === "Owner" ||
      userRole === "Volunteer" ? (
        <AdminHeader />
      ) : (
        <Header />
      )}
      <Outlet />
    </>
  );
};

export default Layout;
