import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";




export const asignPromocode = createAsyncThunk(
  "user/asignPromocode",
  async (query: any, { rejectWithValue, signal }) => {
    try {
      const { data } = await adminAPI.postAsignPromocode(query, signal);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);