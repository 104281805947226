import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api";
import { alertError } from "shared/api/alertError";

interface MarathonDistancePriceData {
  marathonId: string | number;
  distanceId: number;
  promocodeId?: string | number;
  includeInsurance?: boolean;
}

export const getMarathonDistancePrice = createAsyncThunk<any, MarathonDistancePriceData>(
  "getMarathonDistancePrice",
  async (data, { rejectWithValue }) => {
    try {
      const res = await personalCabinetAPI.getMarathonDistancePrice(data);
      return res.data;
    } catch (error: any) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title);
    }
  }
)
