import { useState } from "react";
import trashImg from "assets/images/admin/trash_black.svg";
import style from "./marathonPartnerLogo.module.scss";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import { ICreateMarathon } from "features/createMarathon/types";
import { UiTextField } from "shared/ui/UiInputs/UiTextField";
import { Box } from "@mui/material";

interface MarathonPartnerLogoProps {
  appendParthnerCompany: UseFieldArrayAppend<
    ICreateMarathon,
    `partners.${number}.partnerCompanies`
  >;
  removeParthnerCompany: UseFieldArrayRemove;
  index: number;
  register: UseFormRegister<ICreateMarathon>;
  index2: number;
  item: FieldArrayWithId<
    ICreateMarathon,
    `partners.${number}.partnerCompanies`,
    "id"
  >;
}

export function MarathonPartnerLogo({
  appendParthnerCompany,
  index,
  removeParthnerCompany,
  register,
  index2,
  item: data,
}: MarathonPartnerLogoProps) {
  const [imgUrl, setImgUrl] = useState<string>("");

  const {
    formState: { errors },
  } = useFormContext<ICreateMarathon>();

  const deleteLogo = () => {
    removeParthnerCompany(index);
  };

  if (data.logo?.length) {
    if (data.logo[0].name) {
      const fileReader = new FileReader();
      const fileExtension = data.logo[0]?.name?.split(".").slice(-1).join("");
      fileReader.readAsDataURL(data.logo[0]);
      fileReader.onloadend = () => {
        if (fileExtension === "svg") {
          setImgUrl(
            // @ts-ignore
            fileReader.result?.replace(
              "application/octet-stream",
              "image/svg+xml"
            )
          );
        } else {
          // @ts-ignore
          setImgUrl(fileReader.result);
        }
      };
    }
  }
  // console.log(errors?.partners?.[index]?.partnerCompanies?.[index2]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        background: "#FAFAFA",
        minHeight: "120px",
      }}
    >
      <Box sx={{ width: "30%", height: "100%" }}>
        <div className={style.box}>
          <img height={"110px"} src={imgUrl} alt="Логотип партнёра" />
          <div className={style.box_trash} onClick={deleteLogo}>
            <img src={trashImg} alt="Удалить партнёра" />
          </div>
        </div>
      </Box>
      <Box sx={{ width: "70%" }}>
        <UiTextField
          error={
            errors?.partners?.[index]?.partnerCompanies?.[index2]?.name?.message
          }
          label="Название"
          inputProps={{
            // @ts-ignore
            ...register(`partners.${index}.partnerCompanies.${index2}.name`),
          }}
        />
        <UiTextField
          error={
            errors?.partners?.[index]?.partnerCompanies?.[index2]?.url?.message
          }
          label="Ссылка"
          inputProps={{
            // @ts-ignore
            ...register(`partners.${index}.partnerCompanies.${index2}.url`),
          }}
        />
      </Box>
      {/* <ModalLayout openModal={openModal}>
        <div className={style.modal_content}>
          <div className={style.box_top}>
            <h4>Информация о компании</h4>
          </div>

          <div className={style.box_bottom_btn}>
            <button onClick={handleSave}>Сохранить</button>
            <button onClick={() => setOpenModal(false)}>Отмена</button>
          </div>
        </div>
      </ModalLayout> */}
    </Box>
  );
}
