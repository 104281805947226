import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { CloseIcon } from "assets/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./promoStyle.module.scss";

import { unAsignPromocode } from "entities/admin/model/thunk/unasignPromocodeById";
import { useTranslation } from "react-i18next";

interface PromocodeModal {
  open: boolean;
  onClose: () => void;
  promocode: any;
  handleUpdateState: any;
}

export const UnLinkPromoModal = ({
  onClose,
  open,
  promocode,
  handleUpdateState,
}: PromocodeModal) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  // const debouncedValue = useDebounce<string>(data.email, 800);
  // useEffect(() => {
  //   // @ts-ignore
  //   const promise = dispatch(getVerifiedUsers(debouncedValue));
  //   return () => {
  //     promise.abort();
  //   };
  // }, [dispatch, debouncedValue]);

  const handleClose = async () => {
    onClose();
  };

  const unSignPromodeHandle = async () => {
    setLoading(true);
    await dispatch(
      // @ts-ignore
      unAsignPromocode({ promocodeId: promocode.id })
    );
    await handleUpdateState();
    setLoading(false);
    handleClose();
  };
  const { t, i18n } = useTranslation();

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box>
        <Box className={styles.modal_content}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "20px",
            }}
          >
            <Typography
              className={styles.title}
              sx={{
                color: "#3E444A",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {t("procode_unlinking")}
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography className={styles.body}>
            {!(i18n.language === "kg") ? (
              <>
                {t("unlink_promocode")} {promocode?.code}
                <br />
                {t("unlink_promocode_2")}{" "}
                <strong>{promocode?.user?.fullName}</strong>
              </>
            ) : (
              <>
                {t("unlink_promocode")}{" "}
                <strong>{promocode?.user?.fullName}</strong> <br />
                {promocode?.code} {t("unlink_promocode_2")}
              </>
            )}
          </Typography>
          <Divider sx={{ width: "100%", mb: "24px" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
              gap: "8px",
            }}
          >
            <Button
              sx={{
                fontFamily: "Raleway",
                textTransform: "capitalize",
                fontWeight: "500",
                position: "relative",
                borderRadius: "12px",
                color: "#0090D1",
                minWidth: "170px",
              }}
              onClick={handleClose}
            >
              {t("corporateClient.cancel")}
            </Button>
            <Button
              sx={{
                flex: 1,
                maxWidth: "200px",
                fontFamily: "Raleway",
                textTransform: "capitalize",
                fontWeight: "500",
                position: "relative",
                background: "#0090D1",
                borderRadius: "12px",
                height: "40px",
                color: "white",
                "&:hover": { backgroundColor: "#0090D1" },
              }}
              onClick={unSignPromodeHandle}
            >
              {loading ? (
                <CircularProgress size={20} className={styles.buttonProgress} />
              ) : (
                t("un_asign_promocode")
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
