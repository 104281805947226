import { MenuItem, Select } from "@mui/material";
import { selectStyle } from "shared/lib/style";
import style from "./usersFiltersSelect.module.scss";

function UsersFiltersSelect({
  nameLabel,
  placeholder,
  arr,
  setSelected,
  selected,
}) {
  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  return (
    <label
      htmlFor="editProfile-country"
      className={`label-personalCabinet ${style.label}`}
    >
      <div>
        <p>{nameLabel}</p>
        <p onClick={() => setSelected(null)}>
          {selected !== "" ? "Сбросить" : ""}
        </p>
      </div>
      <Select
        value={selected}
        onChange={handleChange}
        id="editProfile-country"
        className={`input-personalCabinet`}
        sx={selectStyle}
        fullWidth
        renderValue={
          selected !== ""
            ? undefined
            : () => (
                <MenuItem style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                  <em>{placeholder}</em>
                </MenuItem>
              )
        }
        displayEmpty
      >
        {arr?.map((item, index) => (
          <MenuItem value={item.id} key={index}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </label>
  );
}

export default UsersFiltersSelect;
