import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "shared/lib/hooks/hooks";
import { useTranslation } from "react-i18next";
import axiosApi from "shared/api/axiosApi";
import { getApplicationsByMarathonId } from "entities/admin/model/thunk/getApplicationsByMarathonId";
import { getMarathonById } from "entities/admin/model/thunk/getMarathonById";
import MarathonInfo from "entities/admin/ui/MarathonInfo/MarathonInfo";
import { getMarathonByIdForAdmin } from "../../model/thunk/getMarathonByIdForAdmin";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { createPaths } from "shared/lib/createPaths";
import { ADMIN_PANEL_PATH, MARATHONS_PATH } from "pages/paths";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Filters from "entities/admin/ui/Filters/Filters";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import pwdIcon from "assets/images/admin/userDetail/disabled_people.svg";
import ImportExcelFileModal from "entities/admin/ui/ImportExcelFileModal/ImportExcelFile";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { countriesArr } from "entities/viewer/lib/profileData";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  CloseFilter,
  ExportExcelFile,
  ImportExcelFile,
  ShowFilter,
} from "assets/icons/index";
import searchIcon from "assets/images/admin/search-icon.svg";
import russianFlag from "assets/images/header/russianFlag.svg";
import britishFlag from "assets/images/header/britishFlag.svg";
import kyrgyzFlag from "assets/images/header/kyrgyzFlag.svg";
import {
  exportExcelFileButton,
  importExcelFileButton,
  participantListTableCell,
  showFilterButton,
} from "entities/admin/lib/MUIStyles/MUIStyles";
import { styled } from "@mui/material/styles";
import styles from "../ParticipantsList/ParticipantsList.module.scss";
import {
  setAge,
  setCountry,
  setCountryIndex,
  setGender,
  setIsShowFilter,
  setParticipantStatus,
  setParticipantType,
  setPayment,
  setSpecificDistance,
  setStartPackage,
  setPage,
  setPageSize,
} from "../../model/slice";
import { getParticipantInfoBySearchName } from "../../model/thunk/getParticipantInfoBySearchName";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const languageDropDownMenu = [
  { language: "English", image: britishFlag, value: "0" },
  { language: "Русский", image: russianFlag, value: "1" },
  { language: "Кыргызча", image: kyrgyzFlag, value: "2" },
];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: "24px 0 10px 0",
            "@media screen and (max-width: 550px)": {
              padding: "10px 0 10px 0",
            },
          }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ECF2F7",
    color: "#3E444A",
    padding: "8px",
    fontFamily: "Raleway",
    fontSize: "12px",
    lineHeight: "20px",
    border: "none",
    fontWeight: "700 !important",
    "@media(max-width: 1000px)": {
      // fontSize: '8px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F5FAFF",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ParticipantsDistanceList: React.FC = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRole = useAppSelector((state) => state.users.userRole);
  const distances = useAppSelector((state) => state.marathon.distances);
  const getParticipantsListLoading = useAppSelector(
    (state) => state.marathon.getParticipantsListLoading
  );
  const participantsAmount = useAppSelector(
    (state) => state.marathon.participantsCount
  );
  const participantsList = useAppSelector(
    (state) => state.marathon.participantsList
  );
  const participantsListMarathonName = useAppSelector(
    (state) => state.marathon.participantsListMarathonName
  );

  console.log(participantsListMarathonName, 'participantsListMarathonName');
  const gender = useAppSelector((state) => state.marathon.gender);
  const isShowFilter = useAppSelector((state) => state.marathon.isShowFilter);
  const specificDistance = useAppSelector(
    (state) => state.marathon.specificDistance
  );
  const age = useAppSelector((state) => state.marathon.age);
  const country = useAppSelector((state) => state.marathon.country);
  const countryIndex = useAppSelector((state) => state.marathon.countryIndex);
  const payment = useAppSelector((state) => state.marathon.payment);
  const participantType = useAppSelector(
    (state) => state.marathon.participantType
  );
  const startPackage = useAppSelector((state) => state.marathon.startPackage);
  const participantStatus = useAppSelector(
    (state) => state.marathon.participantStatus
  );
  const page = useAppSelector((state) => state.marathon.page);
  const pageSize = useAppSelector((state) => state.marathon.pageSize);

  const [toggle, setToggle] = useState(false);
  const [markFileLoading, setMarkFileLoading] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [excelFile, setExcelFile] = useState(null);
  const [participantSearch, setParticipantSearch] = useState<string>(
    sessionStorage.getItem("sortAllUserAdmin") || ""
  );
  const [insuranceFileLoading, setInsuranceFileLoading] =
    useState<boolean>(false);
  const [exportFileLoading, setExportFileLoading] = useState<boolean>(false);
  const [isExcelFileModalOpen, setIsExcelFileModalOpen] =
    useState<boolean>(false);
  const hiddenFileInput = React.useRef<null | HTMLInputElement>(null);
  const [isLanguageDropDownVisible, setIsLanguageDropDownVisible] =
    useState<boolean>(false);
  const [language, setLanguage] = useState<string>("0");
  const debouncedValue = useDebounce<string>(participantSearch, 300);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);
  const handleMemberSearch = (participant: string) => {
    console.log("render");

    sessionStorage.setItem("sortAllUserAdmin", participant);
    setParticipantSearch(participant);
  };
  const closeExcelFileModal = () => setIsExcelFileModalOpen(false);
  const handleSetLanguage = (event: SelectChangeEvent) =>
    setLanguage(event.target.value as string);

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const modifiedParticipantList = participantsList.map((participant) => {
    const dob = new Date(
      dayjs(participant.user.dateOfBirth).year(),
      dayjs(participant.user.dateOfBirth).month(),
      dayjs(participant.user.dateOfBirth).day()
    );
    const dobNow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
    let age;

    age = today.getFullYear() - dob.getFullYear();

    if (today < dobNow) {
      age = age - 1;
    }

    return { ...participant, user: { ...participant.user, dateOfBirth: age } };
  });

  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(setSpecificDistance(""));
    await dispatch(setGender(""));
    await dispatch(setAge(""));
    await dispatch(setCountry(null));
    await dispatch(setPayment(""));
    await dispatch(setParticipantType(""));
    await dispatch(setStartPackage(""));
    await dispatch(setParticipantStatus(""));
    await dispatch(setPage(1));

    if (params.id) {
      await dispatch(
        getParticipantInfoBySearchName({ participantSearch, id: +params.id })
      );
    }
  };


  const getParticipantInfo = useCallback(async () => {
    await dispatch(
      getParticipantInfoBySearchName({ participantSearch: debouncedValue, id: params.id })
    );
  }, [dispatch, debouncedValue, params.id]);

  useEffect(() => {
    getParticipantInfo();
  }, [getParticipantInfo])

  useEffect(() => {
    if (params.id) {
      dispatch(getMarathonById(+params.id));
      dispatch(getMarathonByIdForAdmin(+params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (!participantSearch) {
      dispatch(
        getApplicationsByMarathonId({
          page,
          pageSize,
          gender,
          country,
          countryIndex,
          startPackage,
          payment,
          specificDistance,
          age,
          participantStatus,
          participantType,
          id: params.id,
        })
      );
    }
  }, [
    dispatch,
    gender,
    country,
    countryIndex,
    startPackage,
    payment,
    specificDistance,
    age,
    participantStatus,
    page,
    pageSize,
    participantType,
    params.id,
    participantSearch,
  ]);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    setExcelFile(fileUploaded);
  };

  const handleOnPageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    dispatch(setPage(page));
  };

  const downloadInsuranceFileHandler = async () => {
    setInsuranceFileLoading(true);
    try {
      const response = await axiosApi.get(
        `/insurances/marathon/${params.id}/insurances`,
        { responseType: "blob" }
      );
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        decodeURI(response.headers["content-disposition"].split("=")[1])
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      if (response.status === 200) {
        setInsuranceFileLoading(false);
      }
    } catch (error) {
      toast.error("Ой! Что-то пошло не так, попробуйте обновить страницу...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
        },
      });
      setInsuranceFileLoading(false);
    }
  };

  const downloadExcelFileHandler = async () => {
    setExportFileLoading(true);
    try {
      const response = await axiosApi.get(
        `/applications/marathon/${params.id}/excel`,
        { responseType: "blob" }
      );
      console.log(response.data);
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        decodeURI(response.headers["content-disposition"].split("=")[1])
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      if (response.status === 200) {
        setExportFileLoading(false);
      }
    } catch (error) {
      toast.error("Ой! Что-то пошло не так, попробуйте обновить страницу...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
        },
      });
      setExportFileLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setInsuranceFileLoading(true);
      try {
        const response = await axiosApi.put(
          `/applications/marathon/${params.id}/corporateIssuance/excel`,
          { excelFile },
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );

        if (response.data === 200) {
          setInsuranceFileLoading(false);
          setIsExcelFileModalOpen(true);
        }
      } catch (error) {
        //TODO: Ошибку обработать
        setInsuranceFileLoading(false);
      }
    };

    if (excelFile) {
      fetchData();
    }
  }, [excelFile, params.id]);

  const breadcrumbs = [
    <Link
      to={createPaths(ADMIN_PANEL_PATH, MARATHONS_PATH)}
      key={"0"}
      className={styles.breadcrumbsLink}
    >
      Марафоны
    </Link>,
    <Typography
      key="1"
      sx={{
        fontFamily: "Raleway, serif",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#2C2C35",
      }}
      className={styles.breadcrumbsText}
    >
      {participantsListMarathonName} {dayjs().year()}
    </Typography>,
  ];

  return (
    <>
      <div className={styles.participantsTopContainer}>
        <div className={styles.participantsTop}>
          <Stack spacing={2} sx={{ mb: "20px" }}>
            <Breadcrumbs
              sx={{ color: "#0090D1" }}
              separator={<NavigateNextIcon fontSize="small" />}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <h2 className={styles.participantsTopTitle}>
            {participantsListMarathonName} {dayjs().year()}
          </h2>
        </div>
        {isLanguageDropDownVisible ? (
          <div className={styles.participantsTopLanguageDropDown}>
            <Box sx={{ minWidth: 145 }}>
              <FormControl fullWidth>
                <Select
                  value={language}
                  sx={{
                    ".MuiOutlinedInput-input": {
                      padding: "5px 12px",
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #C0D4E3",
                      borderRadius: "12px",
                    },
                    ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #C0D4E3",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #C0D4E3",
                    },
                  }}
                  onChange={handleSetLanguage}
                >
                  {languageDropDownMenu.map((menuItem, index) =>
                    menuItem.value === language ? (
                      <MenuItem
                        value={menuItem.value}
                        key={index}
                        style={{ display: "none" }}
                        sx={{
                          fontFamily: "Raleway",
                          fontSize: "14px",
                          color: "#3E444A",
                          lineHeight: "24px",
                        }}
                      >
                        <img
                          src={menuItem.image}
                          className={styles.visibleFlag}
                          alt={`${menuItem.value + "flag"}`}
                        />
                        <span className={styles.visibleLanguage}>
                          {menuItem.language}
                        </span>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        value={menuItem.value}
                        key={index}
                        sx={{
                          fontFamily: "Raleway",
                          fontSize: "14px",
                          color: "#3E444A",
                          lineHeight: "24px",
                          fontWeight: 600,
                        }}
                      >
                        <img
                          src={menuItem.image}
                          className={styles.dropDownFlag}
                          alt={`${menuItem.value + "flag"}`}
                        />
                        <span className={styles.dropDownLanguage}>
                          {menuItem.language}
                        </span>
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : null}
      </div>
      <div className={styles.participantsListContainer}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label="Корпоративная выдача"
                sx={{
                  fontSize: "16px",
                  fontFamily: "Raleway",
                  textTransform: "none",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Информация"
                {...a11yProps(1)}
                sx={{ fontSize: "16px", textTransform: "none" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ImportExcelFileModal
              isExcelFileModalOpen={isExcelFileModalOpen}
              closeExcelFileModal={closeExcelFileModal}
            />
            <span className={styles.participantsListTop}>
              <span className={styles.participantsListTitle}>
                Список участников
                <span className={styles.participantsListAmount}>
                  {participantsAmount}
                </span>
              </span>
              {userRole === "Volunteer" ? null : (
                <span className={styles.excelButtonsContainer}>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    startIcon={<ExportExcelFile />}
                    disabled={insuranceFileLoading}
                    variant={insuranceFileLoading ? "contained" : "text"}
                    sx={{ ...importExcelFileButton, position: "relative" }}
                    onClick={downloadInsuranceFileHandler}
                  >
                    {t("insurance.generateList")}
                    {insuranceFileLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                  {/* <Button
                    startIcon={<ExportExcelFile />}
                    disabled={exportFileLoading}
                    onClick={downloadExcelFileHandler}
                    variant="contained"
                    sx={exportExcelFileButton}
                  >
                    {t("insurance.export")}
                    {exportFileLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button> */}
                </span>
              )}
            </span>
            <form
              onSubmit={handleSearch}
              className={styles.participantsFilterContainer}
            >
              <span className={styles.participantInputContainer}>
                <input
                  className={styles.participantInput}
                  placeholder="sfvjhsbfvjbfs"
                  type="text"
                  value={participantSearch}
                  onChange={(e) => handleMemberSearch(e.target.value)}
                />
                <button>
                  <img
                    className={styles.participantSearchIcon}
                    src={searchIcon}
                    alt="Поиск"
                  />
                </button>
              </span>
              <Button
                startIcon={isShowFilter ? <CloseFilter /> : <ShowFilter />}
                variant="contained"
                sx={showFilterButton}
                onClick={() => dispatch(setIsShowFilter(!isShowFilter))}
              >
                {isShowFilter ? "Скрыть фильтры" : "Показать фильтры"}
              </Button>
            </form>
            {isShowFilter ? (
              <Filters
                distances={distances}
                gender={gender}
                setGender={setGender}
                age={age}
                setAge={setAge}
                country={country}
                setCountry={setCountry}
                setCountryIndex={setCountryIndex}
                payment={payment}
                setPayment={setPayment}
                participantType={participantType}
                setParticipantType={setParticipantType}
                startPackage={startPackage}
                setStartPackage={setStartPackage}
                participantStatus={participantStatus}
                setParticipantStatus={setParticipantStatus}
                specificDistance={specificDistance}
                setSpecificDistance={setSpecificDistance}
                setPage={setPage}
              />
            ) : (
              false
            )}
            {getParticipantsListLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "25px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : participantsList.length === 0 ? (
              <Box>
                <p className={styles.noRecords}>Нет записей</p>
              </Box>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  marginTop: "30px",
                  borderRadius: "12px 12px 0 0",
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        sfvsrf
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        ФИО
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        ФИО (на родном языке)
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Дистанция
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Пол
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Возраст
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Страна
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Способ оплаты
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Старт пакет
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ border: "none", fontSize: "14px" }}
                        align="left"
                      >
                        Статус участника
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modifiedParticipantList.map((participant: any) => (
                      <StyledTableRow
                        key={participant.id}
                        sx={{
                          "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                          navigate(
                            `/marathons/listOfParticipants/participant/${participant.id}`
                          );
                        }}
                      >
                        <StyledTableCell
                          align="left"
                          sx={participantListTableCell}
                        >
                          {participant.isPWD ? (
                            <img
                              src={pwdIcon}
                              className={styles.pwdImage}
                              alt="Люди с ограниченными возможностями"
                            />
                          ) : null}
                          {participant.number}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{
                            ...participantListTableCell,
                            display: "flex",
                            flexDirection: "column",
                            fontWeight: 600,
                          }}
                        >
                          <span>
                            {participant.user.name +
                              " " +
                              participant.user.surname}
                          </span>
                          <span className={styles.participantEmail}>
                            {participant.user.email || "Почта отсутсвует"}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ borderBottom: "none" }}
                        >
                          {participant.user.nativeName
                            ? `${participant.user.nativeName} ${participant.user.nativeSurname}`
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{
                            border: "none",
                            fontFamily: "Raleway",
                            color: "#3E444A",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "20px",
                            paddingLeft: "8px",
                          }}
                        >
                          {participant.distance}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={participantListTableCell}
                        >
                          {participant.user.gender ? "М" : "Ж"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={participantListTableCell}
                        >
                          {participant.user.dateOfBirth}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={participantListTableCell}
                        >
                          {countriesArr[participant.user.country]}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={participantListTableCell}
                        >
                          {participant.payment === 0 ? (
                            <span className={styles.pwd}>
                              Льготный участник
                            </span>
                          ) : null}
                          {participant.payment === 1 ? (
                            <span className={styles.voucher}>Ваучер</span>
                          ) : null}
                          {participant.payment === 2 ? (
                            <span>Онлайн</span>
                          ) : null}
                          {participant.voucher ? (
                            <p>{participant.voucher}</p>
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={participantListTableCell}
                        >
                          {participant.starterKit === 0 && "Выдан"}
                          {participant.starterKit === 1 && "Не выдан"}
                          {participant.starterKit === 2 &&
                            "Выдан третьему лицу"}
                          {participant.starterKit === 3 &&
                            "Корпоративная выдача"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{
                            ...participantListTableCell,
                            color:
                              (participant.user.currentStatus === 0 &&
                                "#3E444A") ||
                              (participant.user.currentStatus === 1 &&
                                "#237657") ||
                              (participant.user.currentStatus === 2 &&
                                "#FA0E00") ||
                              (participant.user.currentStatus === 3 &&
                                "#FFD058") ||
                              "#3E444A",
                          }}
                        >
                          {participant.user.currentStatus === 0 &&
                            "Не верифицирован"}
                          {participant.user.currentStatus === 1 &&
                            "Верифицирован"}
                          {participant.user.currentStatus === 2 && "Отклонён"}
                          {participant.user.currentStatus === 3 &&
                            "На рассмотрении"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {participantsList.length === 0 ? null : (
              <Stack
                spacing={2}
                sx={{
                  margin: "30px auto 20px auto",
                  width: "500px",
                  alignItems: "center",
                  "@media (max-width: 515px)": {
                    width: "400px",
                  },
                  "@media (max-width: 420px)": {
                    width: "300px",
                  },
                }}
              >
                <Pagination
                  count={
                    participantsAmount
                      ? Math.ceil(participantsAmount / pageSize)
                      : 5
                  }
                  page={page}
                  onChange={handleOnPageChange}
                  color="primary"
                  size="medium"
                  variant="outlined"
                  shape="rounded"
                  sx={{ margin: "0 auto" }}
                />
                <>
                  <Select
                    value={pageSize}
                    name="pageSize"
                    onChange={(e) => {
                      dispatch(setPageSize(+e.target.value));
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          padding: "0 10px",
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#e3f2fd",
                          },
                        },
                      },
                    }}
                    sx={{
                      borderRadius: "12px",
                      border: "1px solid #C0D4E3",
                      width: "100px",
                      margin: "0 auto",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      ".MuiSelect-select": {
                        padding: "10px 32px 10px 12px",
                        color: "#3E444A",
                        fontFamily: "Raleway",
                        fontSize: "14px",
                        lineHeight: "24px",
                      },
                    }}
                  >
                    <MenuItem
                      value={10}
                      sx={{ borderRadius: "10px" }}
                      className={styles.resetMuiDefaultStyle}
                    >
                      10
                    </MenuItem>
                    <MenuItem value={20} sx={{ borderRadius: "10px" }}>
                      20
                    </MenuItem>
                    <MenuItem value={30} sx={{ borderRadius: "10px" }}>
                      30
                    </MenuItem>
                    <MenuItem value={40} sx={{ borderRadius: "10px" }}>
                      40
                    </MenuItem>
                    <MenuItem value={50} sx={{ borderRadius: "10px" }}>
                      50
                    </MenuItem>
                  </Select>
                  <InputLabel>Количество элементов на странице</InputLabel>
                </>
              </Stack>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MarathonInfo
              language={language}
              setIsLanguageDropDownVisible={setIsLanguageDropDownVisible}
            />
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

export default ParticipantsDistanceList;
